import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import "../css/privacy.css";

function Terms() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header__inner__left">
        <img
          width="150"
          height="64"
          alt=""
          src="https://neveragain.allstatics.com/2019/assets/icon/logo/wondershare-vertical-white.svg"
        />
        <div className="head_img_right_title">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Video Creativity
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Diagram & Graphics
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                PDF Solutions
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Data Management
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Explore AI
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toTop}>
                Business
              </Link>
            </li>

            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Support
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="privacy_box">
        <h1>Terms & Conditions</h1>
        <div className="privacy_inner_box">
          <div className="privacy_inner_txt_div">
            <p>
              WONDERSHARE TECHNOLOGY GROUP CO., LIMITED makes the information
              and products available to you on this website, subject to the
              following terms and conditions. By accessing this website, you
              agree to these terms and conditions. Wondershare reserves the
              right to seek all remedies in law and equity for any violation of
              these terms and conditions.
            </p>
            <p>Any rights not expressly granted herein are reserved.</p>
            <p>
              There are inherent dangers in the use of any Software available
              for downloading on the Internet, and Wondershare Software hereby
              admonishes you to make sure that you completely understand all of
              the risks before downloading any of the Software (including
              without limitation, the potential infection of your system by
              computer viruses and loss of data). You are solely responsible for
              adequate protection and backup of the data and equipment used in
              connection with any of the Software.
            </p>
            <p>
              <strong>IMAGES:</strong> All logos, splash screens, page headers,
              images and graphics displayed on this Site are service marks,
              trademarks, and/or trade dress (collectively, "Marks") of
              Wondershare or its third-party licensors.. Except as expressly
              permitted herein, using, copying, transmitting, displaying,
              modifying or distributing any Marks in any form or by any means
              without the express written permission of Wondershare is
              prohibited and may violate the copyright, trademark, privacy or
              other local laws.
            </p>
            <p>
              <strong>THIRD PARTY WEBSITES AND PRODUCTS: </strong>Wondershare’s
              websites and products may include products or services owned by
              third party websites. Such products or services owned by third
              party websites are only for your convenience. Wondershare shall
              not be responsible for the license restrictions and legality of
              any content of such products or services owned by third party
              websites. You might need to review and agree to applicable rules
              of use when using such products or services owned by third party
              websites. In addition, a link to third party website does not
              imply that Wondershare endorses the site or the products or
              services referenced therein.
            </p>
            <p>
              <strong>INDEMNITY:</strong>You agree to defend, indemnify and hold
              wondershare, its affiliates and their officers, directors, agents,
              and employees harmless from and against any and all claims,
              losses, damages, liabilities, costs, and expenses, including
              attorneys' fees, arising from or related to your user content, use
              of the Site, or violation of any of these Terms.
            </p>

            <p>
              <strong>FEEDBACK:</strong>Any comments or materials sent to
              Wondershare Software, including without limitation feedback, such
              as questions, comments, suggestions or any related information
              regarding the Software, this website or any other products,
              programs or services of Wondershare Software ("Feedback"), shall
              be deemed to be non-confidential. Wondershare Software shall have
              no obligation of any kind with respect to such Feedback and shall
              be free to reproduce, use, disclose, exhibit, display, transform,
              create derivative works and distribute the Feedback to others
              without limitation and shall be free to use any ideas, concepts,
              know-how or techniques contained in such Feedback for any purpose
              whatsoever, including but not limited to developing, manufacturing
              and marketing products incorporating such Feedback.
            </p>

            <p>
              <strong>REPRODUCTIONS:</strong>Any authorized reproductions of any
              of the information contained herein must include copyright
              notices, trademarks or other proprietary legends of Wondershare
              Software, on any copy of the materials made by you. The local laws
              govern the license for the Software and use of this website.
            </p>

            <p>
              <strong>COPYRIGHT: </strong>Copyright in this website (including
              without limitation, text, graphics, logos, sounds and software) is
              owned and licensed by WONDERSHARE TECHNOLOGY GROUP CO., LIMITED
              All materials contained on this site are protected by local and
              international copyright law and may not be copied, reproduced,
              distributed, transmitted, displayed, published adapted, or dealt
              with in any form or by any means or in any media without the prior
              written permission of WONDERSHARE TECHNOLOGY GROUP CO., LIMITED
              You may not alter or remove any copyright or other notice from
              copies of the content.
            </p>
            <p>
              <strong>TRADEMARK: Wondershare</strong>is a trademark of
              WONDERSHARE TECHNOLOGY GROUP CO., LIMITED and legally protected by
              law. It may only be used with the prior written permission of
              WONDERSHARE TECHNOLOGY GROUP CO., LIMITED in each specific
              instance. The use of the Wondershare trademark for commercial
              purposes without the prior written permission of Wondershare will
              constitute trademark infringement and unfair competition in
              violation of the law.
            </p>
            <p>
              For any questions, please enter our Customer Service Center for
              help. Thank you.
              <br />
              The website is operated by WONDERSHARE GLOBAL LIMITED and WONSON
              GLOBAL ENTERPRISES INC which are the subsidiary of WONDERSHARE
              TECHNOLOGY GROUP CO., LIMITED.
              <br />
              VBROADCAST Co., Ltd is our affiliated company, the address of
              VBROADCAST Co., Ltd is 1000N.West Street, Suite 1200,Wilmington,
              New Castle, Delaware19801
            </p>
            <p>
              WONSON GLOBAL ENTERPRISES INC
              <br />
              300 - 3665 Kingsway Vancouver, B.C. V5R-5W2 Canada
              <br />
              WONDERSHARE GLOBAL LIMITED (registration number:2794708)
              <br />
              Unit D, 16/F, One Capital Place, 18 Luard Road, Wan Chai, Hong
              Kong
              <br />
              WONDERSHARE TECHNOLOGY GROUP CO., LIMITED.
              <br />
              West of Donghuan Road, North of 1-4 Road, South of 1-3 Road, Room
              No.2, Floor 6, Building No.8 of East of Liuwu Building, Lhasa,
              China. Contact Us：
              <br />
              shop@zoqo.info
              <br /> (415) 425-9345
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
